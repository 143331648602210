import { getCurrentInstance } from 'vue'

export function useI18n () {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`useI18n should be called in setup().`)
  }

  return instance.proxy.$t
}

